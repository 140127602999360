import request from '../utils/request'
// 首页
export function login(params: any) {
    return request({
        url: '/admin/login',
        method: 'POST',
        params
    })
}

// 主题收藏
// 取消主题
// 收藏主题
// 评论
// 点赞
// 用户详情
// 验证 accessToken
// 获取未读消息数
// 获取已读和未读消息
//  标记全部已读
// 
