<template>
    <div class="login_content">
        <div class="title">
            <h2>业务专用通讯系统</h2>
        </div>
        <div class="input_div">
            <div>
                <input type="text" placeholder="请输入账号" v-model="username">
            </div>
            <div>
                <input type="password" placeholder="请输入密码" v-model="password">
            </div>
        </div>
        <button class="button" @click="login">登录</button>
    </div>
</template>
  
<script>
import { sayHi } from '../main'
import router from "@/router";
import * as api from "../api/user";//引入对应的api
export default {
    data() {
        return {
            username: null,
            password: null,
        }
    },
    methods: {
        login() {
            api.login({ username: this.username, password: this.password }).then((res) => {
                console.log(res);
                if (res.data.code == 1) {
                    this.$message({
                        message: res.data.msg,
                        type: 'success'
                    });
                    this.loginIM(res.data.data.id)
                } else {
                    this.$message.error(res.data.msg);
                }
            });
        },
        loginIM(userID) {
            window.localStorage.setItem("userID", userID)
            sayHi(userID)
            router.push('IM')
        },
    },
}
// import { defineComponent, reactive, nextTick, onMounted, toRefs } from "vue";
// import { TUIEnv } from '../TUIKit/TUIPlugin';
// import { TUIComponents, TUICore, genTestUserSig } from '../TUIKit';
// import TIM from 'tim-js-sdk';
// import router from "@/router";
// let options = {
//     SDKAppID: 70000020 // 接入时需要将0替换为您的即时通信应用的 SDKAppID
// };
// let tim = TIM.create(options);
// export default defineComponent({
//     name: "Aboutus",
//     data() {
//         return {
//             username: ""
//         }
//     },
//     setup() {
//         let state = reactive({
//             hHeight: 0,//页面高度
//         });
//         onMounted(() => {
//             nextTick(() => {
//                 state.hHeight = document.documentElement.clientHeight;
//             })
//         })
//         const data = reactive({
//             env: TUIEnv(),
//             currentModel: 'conversation',
//         });
//         const handleCurrentConversation = (value: string) => {
//             data.currentModel = value ? 'message' : 'conversation';
//         };
//         return {
//             ...toRefs(data),
//             handleCurrentConversation,
//         };
//     },
//     methods: {
//         login() {
//             // let promise = tim.login({
//             //     userID: '20', userSig: genTestUserSig({
//             //         SDKAppID: 70000020,
//             //         secretKey: "898ecf665a74d3b14506608fb82cc5dcbe422b7235aba7fb3a8028c460593726",
//             //         userID: "20"
//             //     }).userSig
//             // }).then(function (imResponse: { data: { repeatLogin: boolean; errorInfo: any; }; }) {
//             //     console.log(imResponse.data); // 登录成功
//             //     router.push('IM')
//             //     if (imResponse.data.repeatLogin === true) {
//             //         // 标识帐号已登录，本次登录操作为重复登录。v2.5.1 起支持
//             //     }
//             // }).catch(function (imError: any) {
//             //     console.warn('login error:', imError); // 登录失败的相关信息
//             // });
//             // console.log("111111111111222222", promise);
//         }
//     },
// });
</script>
  
<style>
body {
    background: #fff;
}

.login_content {
    width: 500px;
    height: 500px;
    margin: 150px auto 0;
    border-radius: 20px;
    background: rgb(223, 223, 223);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
    text-align: center;
}

.input_div>div {
    margin: 20px auto;
}

.input_div>div input {
    width: 400px;
    height: 30px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto 0;
    font-size: 16px;
    text-align: center;
    border-radius: 6px;
    border: 1px solid #000;
}

.button {
    width: 400px;
    height: 60px;
    background-color: aquamarine;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    border-radius: 20px;
    font-size: 25px;
    margin-top: 30px;
    margin: 30px auto;
    border: #FFFFFF solid 0px;
}
</style>